@font-face {
  font-family: "Nunito";
  src: url('./fonts/NunitoSans-Regular.ttf') format("truetype");
}

@font-face {
  font-family: "Nunito SemiBold";
  src: url('./fonts/NunitoSans-SemiBold.ttf') format("truetype");
}

@font-face {
  font-family: "Nunito Bold";
  src: url('./fonts/NunitoSans-Bold.ttf') format("truetype");
}

body {
  margin: 0;
  font-family: "Nunito";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2efe4;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
